import React from "react";
import Layout from "../components/Layout";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import "../styles/360-catalog.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import CommonSection from "../components/CommonSection";
import GatsbyImage from "gatsby-image";
import { Image } from "react-bootstrap";
const Metaverse = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>HelloAR: Metaverse | Metaverse Company</title>
        <meta
          name="description"
          content="Launch your company in metaverse with HelloAR. Let us know your launch plan and we will set you up in metaverse."
        />
        <meta name="keywords" content="metaverse, metaverse company" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
      </Helmet>
      <div className="container-fluid catalog">
        <div className="spacer d-sm-none"></div>
        <div className="spacer"></div>
        <div className="spacer-sm"></div>
        <CommonSection
          title="Launch your business in the metaverse"
          buttonText="REQUEST A DEMO"
          sub="Awe your customers with your virtual store. Let them walk around, see and interact with your products, and place orders in real time"
          showCta
        >
          <Image src="/images/metaverse/metaverse.jpg" fluid />
        </CommonSection>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer-sm"></div>
      </div>
    </Layout>
  );
};

export default Metaverse;

// export const imgs = graphql`
//   query {
//     imageOne: file(relativePath: { eq: "metaverse/metaverse.jpg" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;
